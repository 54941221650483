import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import validator from "validator";
import { updateInfoToUser } from "../../helpers/api";

export default function UpdatePassword({
  show,
  onCancel,
  onClose,
  onSuccess,
  onFail,
  userDefault,
}) {
  const [user, setUser] = useState({
    passowrd: "",
    rpassword: "",
  });

  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [connectionError, setConnectionError] = useState("");
  useEffect(() => {
    setConnectionError("");
    setShowSuccess(false);
  }, [user]);

  useEffect(() => {
    setUser({
      password: "",
      rpassword: "",
    });
  }, [show]);

  useEffect(() => {
    const validateInfo = () => {
      let errorInfo = {
        foundError: false,
        password: "",
        rpassowrd: "",
      };

      if (user.password === "" || typeof user.password === "undefined") {
        errorInfo.foundError = true;
      } else {
        if (validator.isLength(user.password, { min: 0, max: 7 })) {
          if (validator.isLength(user.password, { min: 1, max: 7 }))
            errorInfo.password = "Pasword should at least be 8 characteres!";
          errorInfo.foundError = true;
        }
      }

      if (user.password !== user.rpassword) {
        if (user.rpassword.toString().trim().length > 0)
          errorInfo.rpassword = "Password don't match!";
        errorInfo.foundError = true;
      }

      setErrors(errorInfo);
    };
    validateInfo();
  }, [user]);

  const [errors, setErrors] = useState({
    foundError: false,
    password: "",
    rpassword: "",
  });

  const updateUserInfo = (info) => {
    const data = { ...user, ...info };
    setUser(data);
  };

  const handlerPasword = (e) => {
    e.preventDefault();
    updateUserInfo({ password: e.target.value ? e.target.value : "" });
  };
  const handlerRPasword = (e) => {
    e.preventDefault();
    updateUserInfo({ rpassword: e.target.value });
  };

  const saveUser = (e) => {
    e.preventDefault();
    if (!errors.foundError) {
      setShowLoading(true);
      updateInfoToUser(userDefault, user)
        .then((response) => {
          setShowLoading(false);
          setShowSuccess(true);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((error) => {
          setShowLoading(false);
          setConnectionError(error);
          setShowSuccess(false);
          if (onFail) {
            onFail();
          }
        });
    }
  };
  const form = () => {
    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="******"
            value={user.password}
            onChange={handlerPasword}
          />
          <span style={{ color: "red" }}>{errors.password}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Repeat Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="******"
            value={user.rpassword}
            onChange={handlerRPasword}
          />
          <span style={{ color: "red" }}>{errors.rpassword}</span>
        </Form.Group>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onCancel} animation={false} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{`Update Password For ${userDefault.username}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {connectionError !== "" ? (
          <Alert variant="warning">{connectionError}</Alert>
        ) : (
          <></>
        )}
        {showSuccess ? (
          <Alert variant="success">Password Updated!</Alert>
        ) : (
          <></>
        )}
        {form()}
      </Modal.Body>
      <Modal.Footer>
        {!showSuccess ? (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={saveUser} disabled={showLoading}>
              {showLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <></>
              )}
              Save
            </Button>
          </>
        ) : (
          <Button
            variant="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
