import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import validator from "validator";
import PrettyContainer from "../../prettyContainer/PrettyContainer";
import { updatePassword } from "../../../helpers/api";

export default function UserPage({
  show,
  onCancel,
  onClose,
  onSuccess,
  onFail,
  userDefault,
}) {
  const [user, setUser] = useState({
    passowrd: "",
    rpassword: "",
  });

  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loginError, setloginError] = useState("");
  useEffect(() => {
    setloginError("");
  }, [user]);

  const saveUser = (e) => {
    e.preventDefault();
    if (!errors.foundError) {
      setShowLoading(true);
      updatePassword(user.password)
        .then((response) => {
          setShowLoading(false);
          setShowSuccess(true);
        })
        .catch((error) => {
          setShowLoading(false);
          setloginError(error);
          setShowSuccess(false);
        });
    }
  };

  useEffect(() => {
    const validateInfo = () => {
      let errorInfo = {
        foundError: false,
        password: "",
        rpassowrd: "",
      };

      if (user.password === "" || typeof user.password === "undefined") {
        errorInfo.foundError = true;
      } else {
        if (validator.isLength(user.password, { min: 0, max: 7 })) {
          if (validator.isLength(user.password, { min: 1, max: 7 }))
            errorInfo.password = "Pasword should at least be 8 characteres!";
          errorInfo.foundError = true;
        }
      }

      if (user.password !== user.rpassword) {
        if (user.rpassword.toString().trim().length > 0)
          errorInfo.rpassword = "Password don't match!";
        errorInfo.foundError = true;
      }

      setErrors(errorInfo);
    };
    validateInfo();
  }, [user]);

  const [errors, setErrors] = useState({
    foundError: false,
    password: "",
    rpassword: "",
  });

  const updateUserInfo = (info) => {
    const data = { ...user, ...info };
    setUser(data);
  };

  const handlerPasword = (e) => {
    e.preventDefault();
    updateUserInfo({ password: e.target.value ? e.target.value : "" });
  };
  const handlerRPasword = (e) => {
    e.preventDefault();
    updateUserInfo({ rpassword: e.target.value });
  };

  const form = () => {
    return (
      <>
        {loginError !== "" ? (
          <Alert variant="warning">{loginError}</Alert>
        ) : (
          <></>
        )}
        {showSuccess ? (
          <Alert variant="success">Update Successfull!</Alert>
        ) : (
          <></>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="******"
            value={user.password}
            onChange={handlerPasword}
          />
          <span style={{ color: "red" }}>{errors.password}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Repeat Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="******"
            value={user.rpassword}
            onChange={handlerRPasword}
          />
          <span style={{ color: "red" }}>{errors.rpassword}</span>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <PrettyContainer tittle="UPDATE PASSWORD">
        {form()}
        <Button variant="primary" onClick={saveUser} disabled={showLoading}>
          {showLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <></>
          )}
          Save
        </Button>
      </PrettyContainer>
    </>
  );
}
