import { reactLocalStorage } from "reactjs-localstorage";
const uuid = require("uuid");
const userKey = "igUser";

export const generateUUID = () => {
  var userAgent = window.navigator.userAgent;
  var platform = window.platform;
  var language = window.navigator.language;

  var data = userAgent + platform + language;

  return uuid.v5(data, uuid.v5.URL);
};

export const getUser = () => reactLocalStorage.getObject(userKey);
export const saveUser = (user) => reactLocalStorage.setObject(userKey, user);
export const clearAll = () => reactLocalStorage.clear();
