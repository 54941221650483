exports.config = {
  applications: [
    {
      id: 1,
      img: `${process.env.REACT_APP_DOMAIN}/img/alldata_logo.jpg`,
      tittle: "All Data",
      description: "OEM-accurate mechanical and collision repair information",
      to:
        process.env.REACT_APP_DOMAIN + "/ext/applications/alldata/igMY/repair",
    },
    {
      id: 2,
      img: `${process.env.REACT_APP_DOMAIN}/img/identifix_logo.jpg`,
      tittle: "Identifix",
      description: "Source for diagnostic and repair information",
      to: "https://identifix.cardexapp.com/CreateVehicle/Index?LocationId=13&RemoteInfo=QwB1AHMAdABvAG0AZQByAFIATwA9ACYAWQBlAGEAcgA9ADAAJgBNAGEAawBlAD0AJgBNAG8AZABlAGwAPQAmAFMAdQBiAE0AbwBkAGUAbAA9ACYARQBuAGcAaQBuAGUAUwBpAHoAZQA9ACYAVgBpAG4APQA%3D",
    },
  ],
};
