import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { config } from "./config";
import "./menu.css";
import { getUser } from "../../helpers/localStorage";

export default function Menu({ username, children }) {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const user = getUser();

  const handleOnclickItem = (item) => {
    if (item.to) {
      navigate(item.to);
      return;
    }

    if (item.action) {
      item.action();
    }
  };

  const mapItems = (items) => {
    return items.map((item, i) => {
      let show = true;
      if (item.roles) {
        const hasRole = item.roles.find((role) => role === user.type);
        if (typeof hasRole !== "undefined") {
          show = true;
        } else {
          show = false;
        }
      }

      if (show) {
        return (
          <li
            className={item.to === location.pathname ? "ig-menu-active" : ""}
            key={i}
            onClick={() => {
              handleOnclickItem(item);
            }}
          >
            {item.text}
          </li>
        );
      } else {
        return <></>;
      }
    });
  };

  return (
    <div className="ig-menu-screen-wrapper">
      <div className={`ig-menu-side ${show ? "" : "ig-menu-hide"}`}>
        <button
          type="button"
          className="ig-menu-btn-close"
          onClick={() => {
            setShow(false);
          }}
        >
          <span className="ig-menu-icon-cross">X</span>
        </button>
        <div className="ig-tittle">
          <h2>{username}</h2>
        </div>
        <ul>{mapItems(config.main)}</ul>
        <ul style={{ position: "absolute", bottom: "1vh", maxWidth: "90%" }}>
          {mapItems(config.bottom)}
        </ul>
      </div>
      <div className="ig-menu-wraper-container">
        <div className="ig-menu-top">
          <div>
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => {
                setShow(!show);
              }}
            />
          </div>
          <div>
            <span>{username}</span>
          </div>
        </div>
        <div className="ig-menu-container">{children}</div>
      </div>
    </div>
  );
}
