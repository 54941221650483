import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { config } from "./config";
import PrettyContainer from "../../prettyContainer/PrettyContainer";
import { getUser } from "../../../helpers/localStorage";
import { getProfile } from "../../../helpers/api";
import { addParameterToURL } from "../../../helpers/others";

export default function ApplicationsPage() {
  const [userApplications, setUserApplications] = useState(
    getUser().applications
  );

  useEffect(() => {
    getProfile()
      .then((data) => {
        setUserApplications(data.applications);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const createCards = () => {
    return config.applications.map((app, i) => {
      if (userApplications.includes(app.id)) {
        return (
          <Card
            key={i}
            style={{
              width: "10rem",
              cursor: "pointer",
              display: "inline-block",
              margin: "10px",
            }}
            onClick={() => {
              let url = addParameterToURL(app.to, "igN", getUser().username);
              url = addParameterToURL(url, "igT", getUser().accessToken);
              url = addParameterToURL(url, "igApp", app.id);
              window.location.href = url;
            }}
          >
            <Card.Img variant="top" src={app.img} />
            <Card.Body>
              <Card.Title>{app.tittle}</Card.Title>
              <Card.Text>{app.description}</Card.Text>
            </Card.Body>
          </Card>
        );
      }
    });
  };

  return (
    <>
      <PrettyContainer tittle="APPLICATIONS">{createCards()}</PrettyContainer>
    </>
  );
}
