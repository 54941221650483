export const addParameterToURL = (url, paramName, paramValue) => {
  // Check if the URL already contains query parameters
  const separator = url.includes("?") ? "&" : "?";

  // Encode the parameter name and value to ensure they are properly formatted
  const encodedParamName = encodeURIComponent(paramName);
  const encodedParamValue = encodeURIComponent(paramValue);

  // Build the new URL with the added parameter
  const newURL = `${url}${separator}${encodedParamName}=${encodedParamValue}`;

  return newURL;
};
