import React from "react";
import "./prettyContainer.css";

export default function PrettyContainer({ tittle, children }) {
  return (
    <div className="ig-prettycontainer-wrapper">
      <div className="ig-prettycontainer-header">{tittle}</div>
      <div>{children}</div>
    </div>
  );
}
