import React from "react";
import $ from "jquery";
import "../../helpers/jquery.js"
import "vegas/dist/vegas.js";
import "vegas/dist/vegas.css";


class Vegas extends React.Component {
  componentDidMount() {
    this.$el = $(this.el);
    this.$el.vegas({
      delay: 5000,
      timer: false,
      animation: "kenburns",
      transition: "swirlLeft",
      slides: [
        { src: "img/carfigures/bg29-l-1.jpg" },
        { src: "img/carfigures/bg29-l-2.jpg" },
        { src: "img/carfigures/bg29-l-3.jpg" },
      ],
    });
  }

  render() {
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        ref={(el) => (this.el = el)}
      ></div>
    );
  }
}

export default Vegas;
